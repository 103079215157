import React from "react";
import { Spinner } from "reactstrap";
import { withLocalize } from "react-localize-redux";

import "./index.scss";

const Header = ({ name, customerName, loading }) => {

  return (
    <div className="headerWrapper">
      <div className="titleWrapper">
        {loading ? (
          <div className="loadingWrapper">
            <Spinner className="loading" />
          </div>
        ) : (
          <>
            <div className="overlay"></div>
            <div className="title">
              <h2>{name}</h2>
              <div className="lg">ـــــ</div>
              <p>{customerName}</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default withLocalize(Header);

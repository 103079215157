import axios from "axios";
import { getUserlang } from "./sessions";

const http = axios.create();

http.interceptors.request.use(
  async config => {
    config = {
      ...config,
      headers: {
        ...config.headers,
        Locale: getUserlang()
      }
    };
    return config;
  },
  err => Promise.reject(err)
);
export default http;

import React, { useState, useEffect } from "react";
import { withLocalize } from "react-localize-redux";
import { Row, Col } from "reactstrap";
import HeaderLogoWrapper from "../../components/HeaderLogoWrapper";
import Header from "../../components/Header";
import ProjectDetail from "../../components/ProjectDetail";
import TabsComponent from "../../components/Tabs";
import ProjectStatus from "../../components/ProjectStatus";
import ProjectDocument from "../../components/ProjectDocument";
import LoginComponent from "../../components/Login";
import { history } from "../../routing/history";
import { isUserLoggedIn, getUserTokenID } from "../../configuration/sessions";
import http from "../../configuration/http";
import { URLS } from "../../configuration/urls";
import Footer from "../../components/Footer";
import { getUserlang } from "../../configuration/sessions";

import "./index.scss";

const App = ({ translate }) => {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isLoading, setLoadingState] = useState(false);
  const [data, setData] = useState([]);
  const url = history.location.pathname.split("=");
  const urlToken = url[1];

  useEffect(() => {
    if (isUserLoggedIn()) {
      http
        .get(`${URLS.PROJECT_DETAIL_URL}${urlToken}`, {
          headers: {
            Authorization: `Bearer ${getUserTokenID()}`
          }
        })
        .then(result => {
          setLoadingState(false);
          setData(result.data);
        })
        .catch(err => {
          console.log(err);
        });
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserlang()]);

  const detailDisplay = () => (
    <div className="container">
      <div className="projectDetails">
        <Row xs="1" md="2" lg="3">
          <Col className="mb-3">
            <ProjectDetail content={data} />
          </Col>
          <Col  className="mb-3">
            <ProjectStatus completionRate={data && data.completionRate} />
          </Col>
          <Col  className="mb-3">
            <ProjectDocument files={data && data.files} />
          </Col>
        </Row>
      </div>
      {data && (
        <TabsComponent projectCheckLists={data && data.projectCheckLists} />
      )}
    </div>
  );

  return (
    <div>
      {!isLoggedIn ? (
        <div className="loginSection">
          <LoginComponent
            handleUserLoggedIn={setLoggedIn}
            userToken={urlToken}
            fetchData={setData}
          />
        </div>
      ) : (
        <div className="projectSection">
          <HeaderLogoWrapper handleUserLoggedIn={setLoggedIn} />
          <Header
            loading={isLoading}
            name={data && data.name}
            customerName={data && data.customerName}
          />
          {!isLoading && detailDisplay()}
          <Footer />
        </div>
      )}
    </div>
  );
};

export default withLocalize(App);

import React from "react";
import { Card, CardBody } from "reactstrap";
import moment from 'moment';
import { FaClipboard } from "react-icons/fa";
import { Translate } from "react-localize-redux";

import "./index.scss";

const CardComponent = props => {
  const {
    name,
    customerName,
    contractDate
  } = props.content;

  const _contractDate = contractDate && moment(contractDate).format("DD.MM.YYYY");

  return (
    <div className="cardWrapper">
      <Card>
        <div className="cu-cardheader">
          <FaClipboard
            className="align-bottom"
            size="30px"
            color="#E9541E"
          />{" "}
          <Translate id="detail.projectDetail.title" />
        </div>
        <div className="seperator"></div>
        <CardBody>
          <div className="details">
            <dl>
              <dt>
                <Translate id="detail.projectDetail.name" />:
              </dt>
              <dd>{name}</dd>
              <dt>
                <Translate id="detail.projectDetail.customerName" />:
              </dt>
              <dd>{customerName}</dd>
              <dt>
                <Translate id="detail.projectDetail.contractDate" />:
              </dt>
              <dd>{_contractDate}</dd>
            </dl>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default CardComponent;

const NODE_ENV = process.env.NODE_ENV;

let BASE_URL = "http://localhost:8080";

if (NODE_ENV !== 'development') {
  BASE_URL = process.env.PUBLIC_URL;
}

export const URLS = {
  AUTH_URL: `${BASE_URL}/api/ccenter/authenticate`,
  PROJECT_DETAIL_URL: `${BASE_URL}/api/ccenter/projects/secured/`,
  ONE_TIME_TOKEN_URL: `${BASE_URL}/api/ccenter/secured/token/one-time`
};

import React, { useLayoutEffect } from "react";
import { Route, Switch } from "react-router-dom";
import Home from "../container/Home";
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize } from "react-localize-redux";
import english from "../i18n/en/ccenter.json";
import turkish from "../i18n/tr/ccenter.json";
import { getUserlang } from "../configuration/sessions";

const Routing = ({ initialize, addTranslationForLanguage }) => {
  useLayoutEffect(() => {
    initialize({
      languages: [
        { name: "English", code: "en" },
        { name: "Türkçe", code: "tr" }
      ],
      options: {
        renderToStaticMarkup,
        defaultLanguage: getUserlang()
      }
    });
    addTranslationForLanguage(english, "en");
    addTranslationForLanguage(turkish, "tr");
    // initialize and addtranslationForLanugage appear to change every call
    // and hence adding them as deapendencies causes an infinite loop.
    // As they're provided by the library, I don't think there's anything we can do about it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Switch>
      <Route path="/" component={Home} />
    </Switch>
  );
};

export default withLocalize(Routing);

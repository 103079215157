import React from "react";
import { Card, CardBody } from "reactstrap";
import { FaFileDownload } from "react-icons/fa";
import { withLocalize } from "react-localize-redux";

import "./index.scss";
import http from "../../configuration/http";
import {URLS} from "../../configuration/urls";
import { getUserTokenID} from "../../configuration/sessions";

const combineFilesWithCategory = files => {
  return files.reduce((acc, cur) => {
      //if the category is new then add it to the object with its current value
      if (!Object.keys(acc).includes(cur.myFileTypeName)) {
        acc[cur.myFileTypeName] = [{url: cur.url, caption: cur.caption}];
      } else {
        //if it is already exist then add the url to the old ones
        acc[cur.myFileTypeName] = [
          ...acc[cur.myFileTypeName],
          { url: cur.url, caption: cur.caption },
        ];
      }
      return acc;
    }, {});
};



const showFiles = (files, handleDownload) => {

  const combinedFiles = combineFilesWithCategory(files);
  return Object.keys(combinedFiles).map((category) => {
    return (
      <React.Fragment key={category}>
        <b>{category}</b>
        <ul>
        {combinedFiles[category].map((file) => {
          return (
            <li className="bold" key={file.caption}>
              <button
                onClick={e => handleDownload(file.url, file.caption)}
              >
                <span>
                  <b>-</b>
                  {file.caption}
                </span>
              </button>
            </li>
          );
        })}
        </ul>
        <br />
      </React.Fragment>
    );
  });
};

export const ProjectDocument = ({ files, translate }) => {

  const handleDownload = (selectedFileUrl, selectedFileName) =>  {

    http
      .post(`${URLS.ONE_TIME_TOKEN_URL}`,'' , {
        headers: {
          Authorization: `Bearer ${getUserTokenID()}`
        },
      })
      .then((result) => {
        const url = selectedFileUrl + "?renameFile=" + encodeURI(selectedFileName) + "&token=" + result.data;
        window.open(url, '_blank');
      })
      .catch((err) => {
        console.log(err);
      });

  };

  return (
    <div className="cu-cardWrapper">
      <Card>
        <div className="cu-cardheader">
          <FaFileDownload
            className="align-bottom"
            size="30px"
            color="#E9541E"
          />{" "}
          {translate("detail.projectDocuments")}
        </div>
        <div className="seperator"></div>
        <CardBody className="documents">{files && showFiles(files, handleDownload)}</CardBody>
      </Card>
    </div>
  );
};

export default withLocalize(ProjectDocument);

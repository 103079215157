import React from "react";
import "./index.scss";
import { FaPhone, FaEnvelope } from "react-icons/fa";
import { withLocalize } from "react-localize-redux";

const Footer = ({ translate }) => {
  return (
    <div className="footerWrapper">
      <div className="overlay"></div>
      <div className="textWrapper">
        <div className="text-center">
          <h5> {translate("detail.footer.note")}</h5>
        </div>
        <div className="text-center contact" style={{ padding: "2em" }}>
          <a
            href="tel://+902164732203"
          >
            <FaPhone className="ico" />
          </a>
          <a
            href="mailto:info@aterko.com"
          >
            <FaEnvelope className="ico" />
          </a>
        </div>
        <div className="text-center">
          <p>© 2017 Aterko Yapı Danışmanlık San. ve Tic. A.Ş.</p>
        </div>
      </div>
    </div>
  );
};

export default withLocalize(Footer);

import React from "react";
import debounce from "lodash.debounce";
import PropTypes from "prop-types";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
import "./index.scss";

const { node, func } = PropTypes;

const arrowWidth = 20;

class ComponentSlider extends React.Component {
  static displayName = "ComponentSlider";

  static propTypes = {
    children: node.isRequired,
    renderLeftArrow: func,
    renderRightArrow: func,
  };

  static defaultProps = {
    renderLeftArrow: () => <FaAngleLeft style={{ color: "#e9541e" }} />,
    renderRightArrow: () => <FaAngleRight style={{ color: "#e9541e" }} />,
  };

  constructor(props) {
    super(props);

    this.setSliderRef = (element) => {
      this.slider = element;
    };
    this.setSliderContentRef = (element) => {
      this.sliderContent = element;
    };

    this.state = {
      marginLeft: 0,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize());
    this.resetMargin();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize());
  }

  resetMargin = () => {
    if (this.slider && this.sliderContent) {
      this.setState({ marginLeft: 0 });
    }
  };

  handleLeftClicked = () => {
    const currentMarginLeft = this.state.marginLeft;
    const sliderWidth = 130;
    let marginLeft;

    if (currentMarginLeft > sliderWidth) {
      marginLeft = currentMarginLeft - sliderWidth;
    } else {
      marginLeft = 0;
    }

    this.setState({ marginLeft });
  };

  handleRightClicked = () => {
    const currentMarginLeft = this.state.marginLeft;
    const sliderWidth = 130;
    const contentWidth = this.sliderContent.offsetWidth;
    const remainingWidth =
      contentWidth - (sliderWidth - arrowWidth) - currentMarginLeft;
    let marginLeft;

    if (remainingWidth > 0) {
      if (remainingWidth <= sliderWidth) {
        marginLeft = currentMarginLeft + remainingWidth;
      } else {
        marginLeft = currentMarginLeft + sliderWidth;
      }
    } else {
      marginLeft = currentMarginLeft;
    }

    this.setState({ marginLeft });
  };

  handleResize = () => {
    this.updateFn =
      this.updateFn ||
      debounce(() => {
        this.resetMargin();
      }, 200);
    return this.updateFn;
  };

  renderLeftArrow = () => {
    if (this.state.marginLeft !== 0) {
      return (
        <button className="caret caret-left" onClick={this.handleLeftClicked}>
          {this.props.renderLeftArrow()}
        </button>
      );
    }
    return null;
  };

  renderRightArrow = () => {
    const currentMarginLeft = this.state.marginLeft;
    const sliderWidth = this.slider ? this.slider.offsetWidth : 0;
    const contentWidth = this.sliderContent
      ? this.sliderContent.offsetWidth
      : 0;
    const remainingWidth =
      contentWidth - (sliderWidth - arrowWidth) - currentMarginLeft;

    if (remainingWidth > 0) {
      return (
        <button className="caret caret-right" onClick={this.handleRightClicked}>
          {this.props.renderRightArrow()}
        </button>
      );
    }
    return null;
  };

  render = () => {
    return (
      <div className="component-slider" ref={this.setSliderRef}>
        {this.renderLeftArrow()}
        <div className="slider-container">
          <div
            className="slider-content"
            ref={this.setSliderContentRef}
            style={{ marginLeft: `-${this.state.marginLeft}px` }}
          >
            {this.props.children}
          </div>
        </div>
        {this.renderRightArrow()}
      </div>
    );
  };
}

export default ComponentSlider;

import React from "react";
import { Card, CardBody } from "reactstrap";
import { PieChart, Pie, Cell } from "recharts";
import { FaChartPie, FaSquareFull } from "react-icons/fa";
import { withLocalize } from "react-localize-redux";

import "./index.scss";

const COLORS = ["#E9541E", "#54595F"];

export const ProjectStatus = ({ completionRate, translate }) => {

  const chartData = [
    { name: translate("detail.rateOfCompletedItems"), value: completionRate },
    { name: translate("detail.rateOfNotCompletedItems"), value: 100 - completionRate }
  ];

  return (
    <div className="cardWrapper cu-cardWrapper">
      <Card>
        <div className="cu-cardheader">
          <FaChartPie className="align-bottom" size="30px" color="#E9541E" />{" "}
          {translate("detail.projectStatusRate")}
        </div>
        <div className="seperator"></div>
        <CardBody>
          <div className="chart-container">
            <div className="fr-square">
              <FaSquareFull size="1.5em" color="#E9541E" />{" "}
              {`${chartData[0].name} ${chartData[0].value}%`}
            </div>
            <PieChart width={260} height={260}>
              <Pie
                data={chartData}
                dataKey="value"
                // cx={125}
                // cy={125}
                outerRadius={100}
                fill="#8884d8"
              >
                {chartData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              {/* <Tooltip /> */}
            </PieChart>
            <div className="sc-square">
              <FaSquareFull size="1.5em" color="#54595F" />{" "}
              {`${chartData[1].name} ${chartData[1].value}%`}
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default withLocalize(ProjectStatus);

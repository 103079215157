import React, { useState, useEffect } from "react";
import { FaRegCircle, FaCircle, FaUser, FaRegUser } from "react-icons/fa";
import ComponentSlider from "./ComponentSlider";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Progress,
  Collapse,
} from "reactstrap";
import Images from "../Images";
import classnames from "classnames";
import "./index.scss";
import { withLocalize } from "react-localize-redux";

const TabsComponent = ({ projectCheckLists, translate }) => {
  const [activeTab, setActiveTab] = useState(
    projectCheckLists && projectCheckLists[0].id
  );

  const [listData, setListData] = useState();

  useEffect(() => {
    setListData(projectCheckLists);
    setActiveTab(projectCheckLists && projectCheckLists[0].id);
  }, [listData, projectCheckLists]);

  const handleTabToggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const projectCheckListNavItems =
    projectCheckLists &&
    projectCheckLists.map((data, index) => {
      return (
        <NavItem key={index}>
          <div className="tabs-card-wrapper">
            <NavLink
              key={index}
              className={classnames({
                active: activeTab === data.id,
                activeNavTab: true,
              })}
              onClick={() => handleTabToggle(data.id)}
            >
              {data.name}
            </NavLink>
          </div>
        </NavItem>
      );
    });

  const projectCheckListNavItemsMobile =
    projectCheckLists &&
    projectCheckLists.map((data, index) => {
      return (
        <React.Fragment key={index}>
          <NavItem key={index}>
            <div className="tabs-card-wrapper">
              <NavLink
                key={index}
                className={classnames({
                  active: activeTab === data.id,
                  activeCollapsTab: true,
                })}
                onClick={() => handleTabToggle(data.id)}
              >
                {data.name}
              </NavLink>
            </div>
          </NavItem>
          <Collapse isOpen={activeTab === data.id}>
            <div className="collapse-progress-container">
              <div className="progress-text">{`% ${data.completionRate}`}</div>
              <Progress
                className="collapse-progress-bar-wrapper"
                value={data.completionRate}
              />
            </div>
          </Collapse>
        </React.Fragment>
      );
    });

  const IconStatus = (item, user) => {
    if (item && user) {
      return <FaUser style={{ color: "#e9541e" }} />;
    } else if (item) {
      return <FaCircle style={{ color: "#e9541e" }} />;
    } else if (user) {
      return <FaRegUser style={{ color: "#e9541e" }} />;
    } else {
      return <FaRegCircle style={{ color: "#e9541e" }} />;
    }
  };

  const projectPhaseContent = (projectPhase, index) => {
    return (
      projectPhase && projectPhase.projectCheckItems && projectPhase.projectCheckItems.length > 0 &&
        <React.Fragment key={index}>
        <div className="project-phase-container">
          <b className="project-phase-title">
            {projectPhase.name.replace(/\u0307/g, '')}
          </b>
          {projectPhase.projectCheckItems &&
          projectPhase.projectCheckItems.map((projectCheckItem, index) => {
            return (
              <ul
                style={{listStyleType: "none", paddingInlineStart: "0"}}
                key={index}
                className="project-list-wrapper-ul"
              >
                <li key={`${index}-tr`} className="project-checkItem-li">
                  {IconStatus(
                    projectCheckItem.completed,
                    projectCheckItem.customerIsResponsible
                  )}

                  <span className="project-checkItem-title">
                      {projectCheckItem.name}
                    </span>
                  {projectCheckItem.note && (
                    <React.Fragment>
                      {" - "}
                      <div class="d-inline-block mark small">
                        {projectCheckItem.note}
                      </div>
                    </React.Fragment>
                  )}
                </li>
              </ul>
            );
          })}
        </div>
      </React.Fragment>
    );
  };

  const projectCheckListTable = (projectCheckList) => {
    return projectCheckList.projectPhases.map((projectPhase, index) => {
      return projectPhaseContent(projectPhase, index);
    });
  };

  const projectCheckListTabs =
    projectCheckLists &&
    projectCheckLists.map((projectCheckList, index) => {
      return (
        <TabPane tabId={projectCheckList.id} key={`${index}-wd`}>
          <div className="progress-container">
            <div className="progress-text">{`% ${projectCheckList.completionRate}`}</div>
            <Progress
              className="progress-bar-wrapper"
              value={projectCheckList.completionRate}
            />
          </div>

          {projectCheckListTable(projectCheckList)}
          <div className="img-gallery-container">
            <div className="project-phase-title">
              {translate("gallery.name")}
            </div>
            <Images mediaFiles={projectCheckList.mediaFiles} key={index} />
          </div>
        </TabPane>
      );
    });

  const projectCheckListMobile =
    projectCheckLists &&
    projectCheckLists.map((projectCheckList, index) => {
      return (
        <TabPane tabId={projectCheckList.id} key={`${index}-wd`}>
          {projectCheckListTable(projectCheckList)}
          <div className="img-gallery-container">
            <div className="project-phase-title">
              {translate("gallery.name")}
            </div>
            <Images mediaFiles={projectCheckList.mediaFiles} key={index} />
          </div>
        </TabPane>
      );
    });

  return (
    <div className="tabsWrapper">
      <div className="tabsSection">
        <div className="mobile-wrap">
          <Nav vertical>{projectCheckListNavItemsMobile}</Nav>
          <TabContent activeTab={activeTab}>
            {projectCheckListMobile}
          </TabContent>
        </div>
        <div className="desktop-wrap">
          <ComponentSlider>
            <Nav className="nav-wrapper-ul" tabs>
              {projectCheckListNavItems}
            </Nav>
          </ComponentSlider>
          <TabContent activeTab={activeTab}>{projectCheckListTabs}</TabContent>
        </div>
      </div>
    </div>
  );
};

export default withLocalize(TabsComponent);

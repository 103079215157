import React from 'react';
import { logoutUser } from "../../configuration/sessions";
import ChangeLanguage from "../ChangeLanguage";
import { FaSignOutAlt } from "react-icons/fa";

import "./index.scss";

const HeaderLogoWrapper = ({ handleUserLoggedIn }) => {
  const handleLogout = () => {
    handleUserLoggedIn(false);
    logoutUser();
  };

  return (
    <div className="logoWrapper">
      <div className="logo">
        <img src="logo300.png" alt="logo" />
      </div>
      <div className="optionsWrapper">
        <ChangeLanguage />
        <FaSignOutAlt
          className="logoutButton"
          size="3em"
          onClick={handleLogout}
        />
      </div>
    </div>
  );
};


export default HeaderLogoWrapper;
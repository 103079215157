export function setUserSession(token) {
  return sessionStorage.setItem("atrok_user", JSON.stringify(token));
}
export function setUserLang(lang) {
  return sessionStorage.setItem("atrok_user_lang", JSON.stringify(lang));
}

export function isUserLoggedIn() {
  const user = sessionStorage.getItem("atrok_user");
  return user === null ? false : true;
}

export function getUserTokenID() {
  const user = JSON.parse(sessionStorage.getItem("atrok_user"));
  return user.id_token;
}

export function getUserlang() {
  const lang = JSON.parse(sessionStorage.getItem("atrok_user_lang"));
  if (lang === null) {
    setUserLang("tr");
    return "tr";
  } else {
    return lang;
  }
}

export function logoutUser() {
  sessionStorage.removeItem("userData");
  return sessionStorage.removeItem("atrok_user");
}

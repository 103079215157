import React, { useState } from "react";
import { withLocalize } from "react-localize-redux";
import { Button } from "reactstrap";
import { setUserLang } from "../../configuration/sessions";

import "./index.scss";

const LanguageToggle = ({ languages, activeLanguage, setActiveLanguage }) => {
  const [langValue, setLangValue] = useState("tr");
  const langObj = {
    tr: "ENG",
    en: "TR",
  };

  const translationChange = (e) => {
    const langCode = e.target.textContent === "TR" ? "tr" : "en";
    setLangValue(langCode);
    setActiveLanguage(langCode);
    setUserLang(langCode);
  };

  return (
    <Button className="lang-btn" onClick={translationChange}>{langObj[langValue]}</Button>
  );
};

export default withLocalize(LanguageToggle);

import React from "react";
import { Router } from "react-router-dom";
import Routes from "./routing/routing";
import { history } from "./routing/history";
import { IconContext } from "react-icons";
import { withLocalize, LocalizeProvider } from "react-localize-redux";
import "rc-collapse/assets/index.css";
import "react-circular-progressbar/dist/styles.css";
import "./App.scss";

function App() {
  return (
    <Router history={history}>
      <IconContext.Provider
        value={{ color: "gray", className: "global-class-name" }}
      >
        <LocalizeProvider>
          <Routes />
        </LocalizeProvider>
      </IconContext.Provider>
    </Router>
  );
}

export default withLocalize(App);

import React, { Component } from "react";

export const MY_FILE_TYPE_ID_FOR_IMAGE = 5;
export const MY_FILE_TYPE_ID_FOR_VIDEO = 6;

export default class View extends Component {
  render() {
    const { data } = this.props;

    return (
      <div
        style={{
          backgroundColor: "transparent",
          lineHeight: 0,
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: "100%",
          position: "relative",
          textAlign: "center"
        }}
      >
        {data.fileTypeId === MY_FILE_TYPE_ID_FOR_VIDEO && (
          <video
            autoPlay={false}
            controls
            style={{ width: "100%", height: "70vh" }}
          >
            <source key={data.index} src={data.src} type={"video/mp4"} />
          </video>
        )}
        {data.fileTypeId === MY_FILE_TYPE_ID_FOR_IMAGE && (
          <img
            src={data.src}
            alt=""
            key={data.index}
            style={{ maxWidth: "100%", maxHeight: "70vh", cursor: "pointer" }}
          />
        )}
      </div>
    );
  }
}

import React, { useState, useEffect } from "react";
import {
  InputGroup,
  InputGroupAddon,
  Input,
  Button,
  Spinner,
} from "reactstrap";
import { FaArrowRight } from "react-icons/fa";
import HeaderLogoWrapper from "../HeaderLogoWrapper";

import { Translate, withLocalize } from "react-localize-redux";
import http from "../../configuration/http";
import { URLS } from "../../configuration/urls";
// import ChangeLanguage from "../ChangeLanguage";
import { setUserSession } from "../../configuration/sessions";
import { history } from "../../routing/history";
import "./index.scss";

const LoginComponent = (props) => {
  const [value, setValue] = useState("");
  const [error, setError] = useState();
  const [isLoadingData, setLoadingData] = useState(true);

  const url = history.location.pathname.split("=");
  const urlToken = url[1];

  useEffect(() => {
    if (error !== null || error !== undefined) {
      setLoadingData(false);
    }
  }, [error]);

  const handleChangeEvent = (e) => {
    setValue(e.target.value);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setLoadingData(true);
    setError();

    http
      .post(`${URLS.AUTH_URL}`, {
        guid: props.userToken,
        password: value,
      })
      .then((res) => {
        if (res.status !== "200") {
          setLoadingData(false);
          setError(undefined);
        }
        const { data } = res;
        http
          .get(`${URLS.PROJECT_DETAIL_URL}${urlToken}`, {
            headers: {
              Authorization: `Bearer ${data.id_token}`,
            },
          })
          .then((result) => {
            setLoadingData(false);
            props.handleUserLoggedIn(true);
            props.fetchData(result.data);
            setUserSession({ token: props.userToken, id_token: data.id_token });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        setError(err);
      });
  };

  return (
    <div className="cu-login-wrap">
      <HeaderLogoWrapper handleUserLoggedIn={props.handleUserLoggedIn}/>
      <div className="loginPage">
        <div className="overlay"></div>
        <div className="text-wrap">
          <h2 className="login-title">
            <Translate id="login.title" />
          </h2>
          <div className="line-split" />
          <p>ASET</p>
          <div className="form">
            {isLoadingData ? (
              <Spinner color="secondary" />
            ) : (
              <>
                <form method="POST" onSubmit={handleLogin}>
                  <InputGroup>
                    <Input
                      type="password"
                      placeholder={props.translate("login.password")}
                      name="userpassword"
                      value={value}
                      onChange={handleChangeEvent}
                      required
                    />
                    <InputGroupAddon addonType="append">
                      <Button
                        type="submit"
                        color="warning"
                        className="login-btn-form"
                      >
                        <FaArrowRight
                          style={{ color: "#fff" }}
                          className="login-right-arrow"
                        />
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                </form>
                <br />
              </>
            )}
            {error !== undefined ? (
              <>
                <h2 className="login-err-msg">
                  {props.translate("login.invalidUrlOrPassword")}
                </h2>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withLocalize(LoginComponent);

import React, { useState, useEffect } from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import moment from "moment";
import View from "./view";
import "./index.scss";
import { FaCamera } from "react-icons/fa";

export const MEDIA_TYPE_IMAGE = "image";
export const MY_FILE_TYPE_ID_FOR_IMAGE = 5;
export const MEDIA_TYPE_VIDEO = "video";
export const MY_FILE_TYPE_ID_FOR_VIDEO = 6;

export const Images = ({ mediaFiles }) => {
  const [selectedMedia, setselectedMedia] = useState();
  const [isOpened, setOpenedState] = useState(false);
  const [fetchMedia, setFetchMedia] = useState([]);

  const caption = image => {
    return (
      image &&
      image.relatedDate &&
      moment(image.relatedDate).format("DD.MM.YYYY")
    );
  };

  const mediaToImage = (media, index) => {
    return {
      fileTypeId: media.fileTypeId,
      src: media.imageMediumUrl,
      thumbnail: media.imageThumbnailUrl,
      caption: caption(media),
      key: index
    };
  };
  const mediaToVideo = (media, index) => {
    return {
      fileTypeId: media.fileTypeId,
      src: media.url,
      caption: caption(media),
      key: index
    };
  };

  useEffect(() => {
    const mediaData = [];

    mediaFiles
      .filter(media => media.fileTypeId === MY_FILE_TYPE_ID_FOR_IMAGE)
      .map((media, i) => {
        return mediaData.push(mediaToImage(media, i));
      });
    mediaFiles
      .filter(media => media.fileTypeId === MY_FILE_TYPE_ID_FOR_VIDEO)
      .map((media, i) => {
        return mediaData.push(mediaToVideo(media, i));
      });
    setFetchMedia(mediaData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMediaSelection = img => {
    setselectedMedia(img);
    handleToggle();
  };

  const handleToggle = () => {
    setOpenedState(!isOpened);
  };

  const renderMedia = (image, index) => {
    return (
      <div className="gallery" key={index}>
        {image.fileTypeId === MY_FILE_TYPE_ID_FOR_IMAGE && (
          <>
            <img
              src={image.thumbnail}
              alt=""
              key={index}
              onClick={() => handleMediaSelection(index)}
              style={{ cursor: "pointer" }}
            />
            <div className="desc">{image.caption}</div>
          </>
        )}
        {image.fileTypeId === MY_FILE_TYPE_ID_FOR_VIDEO && (
          <>
            <div className="text-center">
              <div
                key={index}
                onClick={() => handleMediaSelection(index)}
                style={{ cursor: "pointer" }}
              >
                <FaCamera size="200px" color="black" />
              </div>
            </div>
            <div className="desc">{image.caption}</div>
          </>
        )}
      </div>
    );
  };

  return (
    <div className="mediaWrapper">
      <div className="mediaContainer">
        <ModalGateway>
          {isOpened ? (
            <Modal onClose={handleToggle}>
              <Carousel
                components={{ Footer: null, View }}
                views={fetchMedia}
                currentIndex={selectedMedia}
              />
            </Modal>
          ) : null}
        </ModalGateway>

        {fetchMedia.length > 0 &&
          fetchMedia.map((images, index) => renderMedia(images, index))}
      </div>
    </div>
  );
};

export default Images;
